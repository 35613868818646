import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { Blog } from '../actions/'
import { createMarkup, notNilOrEmpty } from '../lib/helpers'
import SEO from '../components/seo'

class PostContainer extends React.Component {
  state = {
    readMore: false,
  }
  componentDidMount() {
    const keys = { slug: this.props.match.params.slug }
    this.props.fetchPost({ keys })
  }
  componentDidUpdate(prevProps) {
    const keys = { slug: this.props.match.params.slug }
    if (!R.equals(this.props.match.params.slug, prevProps.match.params.slug))
      this.props.fetchPost({ keys })
  }
  _onReadMore = () => this.setState({ readMore: !this.state.readMore })

  render() {
    const data = this.props.post

    return (
      <>
        {notNilOrEmpty(data) && (
          <SEO
            description={data.meta.yoast_wpseo_metadesc}
            title={data.title}
            image={data.meta.yoast_wpseo_opengraph_image}
          />
        )}
        <section className="page--blog__entry">
          <div className="hero--emtpy" />
          {data && (
            <div className="row">
              <div className="col-md-12">
                <h3>{data.title}</h3>
                <h4 className="page--blog__entry__published-date">
                  {Moment(data.date).format('MMMM DD, YYYY')}
                </h4>
              </div>
              <div className="content">
                <div dangerouslySetInnerHTML={createMarkup(data.content)} />
              </div>
            </div>
          )}
        </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  post: state.post,
})

const mapDispatchToProps = dispatch => ({
  fetchPost: ({ keys }) => dispatch(Blog._fetchPost({ keys })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostContainer)

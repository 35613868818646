import * as R from 'ramda'
import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { mapIndexed } from '../lib/helpers'

class LocationsList extends React.Component {
  state = {
    steps: 0,
    width: 0,
  }
  _prev = (element, times) => {
    this.setState(
      prevState => ({
        steps: R.equals(this.state.steps, 0) ? 0 : this.state.steps - 1,
      }),
      () => {
        element.scrollTo({
          left: this.state.width - element.clientWidth,
          behavior: 'smooth',
        })
        this.setState({
          width: this.state.width - element.clientWidth,
        })
      }
    )
  }
  _next = (element, times) => {
    this.setState(
      {
        steps: R.lte(this.state.steps, times - 1) ? this.state.steps + 1 : 0,
        width: element.clientWidth + this.state.width,
      },
      () => {
        element.scrollTo({
          left: element.clientWidth * this.state.steps,
          behavior: 'smooth',
        })
      }
    )
  }
  render() {
    return (
      <div className="container">
        {this.props.locations && (
          <React.Fragment>
            <List
              component="nav"
              className="locations-list"
              style={
                R.lte(window.screen.width, 768) &&
                this.props.locations.length > 8
                  ? {
                      columns: `100vw ${Math.ceil(
                        R.divide(this.props.locations.length, 8)
                      )}`,
                    }
                  : {}
              }
            >
              {mapIndexed((item, index) => (
                <ListItem
                  button
                  className="locations-list__item"
                  key={index}
                  onClick={() => this.props.onSelectStore(item)}
                >
                  <ListItemText
                    primary={` ${index + 1}) ${item.title}`}
                    className="locations-list__item__text"
                  />
                </ListItem>
              ))(this.props.locations)}
            </List>
            <div className="row">
              {R.lte(window.screen.width, 768) &&
                !R.equals(this.state.steps, 0) && (
                  <ListItem
                    button
                    className="col btn--nav-results btn--nav-results--prev"
                    disableGutters
                    dense
                    onClick={() =>
                      this._prev(
                        document.querySelector('.locations-list'),
                        Math.ceil(R.divide(this.props.locations.length, 8)) - 1
                      )
                    }
                  >
                    <ListItemIcon>
                      <i className="fa fa-arrow-left" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Previous Results"
                      className="btn--nav-results--prev__text"
                    />
                  </ListItem>
                )}
              {R.lte(window.screen.width, 768) &&
                !R.equals(
                  Math.ceil(R.divide(this.props.locations.length, 8)) - 1,
                  this.state.steps
                ) && (
                  <React.Fragment>
                    <ListItem
                      button
                      className="col btn--nav-results btn--nav-results--next"
                      disableGutters
                      dense
                      onClick={() =>
                        this._next(
                          document.querySelector('.locations-list'),
                          Math.ceil(R.divide(this.props.locations.length, 8)) -
                            1
                        )
                      }
                    >
                      <ListItemText
                        primary="Next Results"
                        className="btn--nav-results--next__text"
                      />
                      <ListItemIcon>
                        <i className="fa fa-arrow-right" />
                      </ListItemIcon>
                    </ListItem>
                  </React.Fragment>
                )}
              <p
                className="col-12 text-center d-lg-none"
                style={{ marginTop: '1rem', color: '#d24b27' }}
              >
                <strong>Tap the pin for location details</strong>
              </p>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default LocationsList

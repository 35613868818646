import * as R from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Drawer,
  IconButton,
  MenuItem,
  ListSubheader,
  Toolbar,
  Typography,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import LocationIcon from '@material-ui/icons/LocationOn'
import Logo from '../assets/imgs/logo.png'
import { mapIndexed, notNilOrEmpty } from '../lib/helpers'

const OffCanvasMenu = props => (
  <aside className="d-lg-none text-center">
    <AppBar position="static" className="app-bar d-lg-none">
      <Toolbar>
        <IconButton onClick={props.toggle} aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Typography
          type="title"
          color="inherit"
          style={{ flex: 1 }}
          className="d-flex flex-column justify-content-center"
        >
          <Link to="/" className="row justify-content-center">
            <img src={Logo} alt="Kneaders Logo" className="app-bar-logo" />
          </Link>
          <span className="row justify-content-center">
            <a
              href="https://order.kneaders.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn--red-outline"
              style={{ float: 'none', margin: '10px 0 10px' }}
            >
              ORDER NOW
            </a>
          </span>
        </Typography>
        {notNilOrEmpty(props.geolocationZip) ? (
          <Link
            className="d-flex flex-column text-center"
            to={{
              pathname: '/locations',
              state: {
                geolocationZip: props.geolocationZip,
                geolocationState: props.geolocationState,
              },
            }}
            style={{
              width: '60px',
            }}
          >
            <i className="fa fa-map-marker fa-2x" />
            <span className="header--nav-desktop__location__address">
              {props.geolocation_address}
            </span>
          </Link>
        ) : (
          <IconButton component={Link} to="/locations">
            <LocationIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
    <Drawer open={props.open} onClose={props.toggle} className="drawer-menu">
      <ListSubheader component="div">Main Menu</ListSubheader>
      <div tabIndex={0} role="button" onClick={props.toggle}>
        <Link to="/">
          <MenuItem>Home</MenuItem>
        </Link>
        {notNilOrEmpty(props.menuItems) &&
          mapIndexed((item, index) => (
            <Link to={`/${item.object_slug}`} key={index}>
              <MenuItem>{item.title}</MenuItem>
            </Link>
          ))(props.menuItems)}
        {notNilOrEmpty(props.topMenuItems) &&
          mapIndexed((item, index) =>
            R.contains('http', item.url) ? (
              <Link
                to={item.url}
                key={index}
                onClick={e => {
                  e.preventDefault()
                  R.contains('http', item.url) &&
                    window.open(item.url, '_blank')
                }}
              >
                <MenuItem>{item.title}</MenuItem>
              </Link>
            ) : (
              <Link to={item.url} key={index}>
                <MenuItem>{item.title}</MenuItem>
              </Link>
            )
          )(props.topMenuItems)}
      </div>
    </Drawer>
  </aside>
)

export default OffCanvasMenu

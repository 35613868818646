import { Api } from '../lib'

const TYPE = {
  get_items: 'GET_ITEMS',
}

const _fetchItems = ({ keys }) => dispatch =>
  Api.get('menu_items', { keys })
    .then(cat => dispatch({ type: TYPE.get_items, payload: cat.data }))
    .catch(err => console.log(err))

export default {
  TYPE,
  _fetchItems,
}

import React from 'react'
import { Link } from 'react-router-dom'

const CustomHeroLink = data =>
  data.external ? (
    <a href={data.url} target="_blank" rel="noopener noreferrer">
      <div
        className="row justify-content-center align-items-end hero hero--single"
        style={{
          background: `url(${data[0].hero.hero_image}) center no-repeat`,
        }}
      >
        <div className="slider--container__content text-center">
          <h1 className="slider--container__content__title">
            {data[0].hero.title}
          </h1>
          <h5 className="slider--container__content__subtitle">
            {data[0].hero.sub_title}
          </h5>
        </div>
      </div>
    </a>
  ) : (
    <Link to={data.url}>
      <div
        className="row justify-content-center align-items-end hero hero--single"
        style={{
          background: `url(${data[0].hero.hero_image}) center no-repeat`,
        }}
      >
        <div className="slider--container__content text-center">
          <h1 className="slider--container__content__title">
            {data[0].hero.title}
          </h1>
          <h5 className="slider--container__content__subtitle">
            {data[0].hero.sub_title}
          </h5>
        </div>
      </div>
    </Link>
  )

export default CustomHeroLink

import * as R from 'ramda'
import {MenuCatItems} from '../actions'

const reducer = (state = {}, action) =>
  R.cond([
    [R.equals(MenuCatItems.TYPE.get_items), R.always(action.payload)],
    [R.T, R.always(state)]
  ])(action.type)

export default reducer

import React from 'react'
import { Link } from 'react-router-dom'
import { createMarkup } from '../lib/helpers'
import IconApple from '../assets/imgs/apple-icon-red.png'

export default props => {
  return (
    <div className="col-12 content-item animated fadeIn">
      <div className="row">
        <div className="col-md-6 wood-bg equalH align--center--v">
          <h3 className="red text-center">{props.title}</h3>
          <div className="hr" />
          <div
            dangerouslySetInnerHTML={createMarkup(
              props.group.menu_item_description
            )}
          />
          <div className="hr" />
          <div className="row align-items-center">
            <Link to="/nutrition" className="col">
              <img className="icon--apple" src={IconApple} alt="Apple Icon" />
              <span>Nutrition</span>
            </Link>
            <div className="col">
              <a
                href={
                  props.group.menu_item_url || 'https://order.kneaders.com/'
                }
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--red-outline btn-sm btn-block"
              >
                Order Now
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 image--bg equalH"
          style={{
            background: `url(${props.group.menu_item_mainimage}) center no-repeat`,
          }}
        />
      </div>
    </div>
  )
}

import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

import { MenuCatItems } from '../actions'
import { mapIndexed, notNilOrEmpty } from '../lib/helpers'
import MenuItemContent from '../components/menu_item_content'
import CustomHeroLink from '../components/CustomMenuHeroLink'

const CateringFile =
  'https://wp.kneaders.com/wp-content/uploads/2022/10/28121151/catering_flyer_Oct2022.pdf'

class MenuCatPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      activeIndex: this.props.match.params.item_slug || '',
    }
  }
  componentDidMount() {
    const keys = { slug: this.props.match.params.category }
    this.props.getItems({ keys })
  }

  componentDidUpdate() {
    const activeItem = document.getElementById(this.state.activeIndex)
    activeItem &&
      window.scrollTo({ top: activeItem.offsetTop, behavior: 'smooth' })
  }

  onActiveIndex = i =>
    this.setState(prevState => ({
      activeIndex: R.equals(i, this.state.activeIndex) ? '' : i,
      active: R.equals(prevState.activeIndex, i) ? !this.state.active : true,
    }))

  redirectItem = item => {
    const itemId = document.getElementById(item)
    return R.equals(item, this.state.activeIndex)
      ? (window.history.pushState(
          {},
          '',
          '/menu/' + this.props.match.params.category
        ),
        this.onActiveIndex(item))
      : (window.history.pushState(
          {},
          '',
          '/menu/' + this.props.match.params.category + `/` + item
        ),
        this.onActiveIndex(item),
        window.scrollTo({ top: itemId.offsetTop, behavior: 'smooth' }))
  }

  render() {
    const data = this.props.menu_items

    return (
      <div>
        {data &&
          R.cond([
            [
              R.equals('soups'),
              R.always(
                <CustomHeroLink
                  url="/soup-schedule"
                  {...data}
                  external={false}
                />
              ),
            ],
            [
              R.equals('catering'),
              R.always(
                <CustomHeroLink url={CateringFile} {...data} external={true} />
              ),
            ],
            [
              R.T,
              R.always(
                <div
                  className="row justify-content-center align-items-end hero hero--single no"
                  style={{
                    background: `url(${data[0].hero.hero_image}) center no-repeat`,
                  }}
                >
                  <div className="slider--container__content text-center">
                    <h1 className="slider--container__content__title">
                      {data[0].hero.title}
                    </h1>
                    <h5 className="slider--container__content__subtitle">
                      {data[0].hero.sub_title}
                    </h5>
                  </div>
                </div>
              ),
            ],
          ])(this.props.match.params.category)}
        <div className="menu-content row">
          {notNilOrEmpty(data) &&
            mapIndexed((item, index) => (
              <div
                id={item.slug}
                className={`menu-content--inner-col--items col-md-4 ${
                  R.equals(this.state.activeIndex, item.slug)
                    ? 'expand'
                    : 'closed'
                } `}
                key={index}
              >
                <div
                  className="link--red menu-content--inner-col__bg"
                  onClick={() => this.redirectItem(item.slug)}
                  style={{
                    background: `url(${item.group.menu_item_mainimage}) center no-repeat`,
                  }}
                >
                  <div className="item--active" />
                </div>
                <h4 className="text-center">{item.title}</h4>
                <div
                  className={`animated ${
                    R.equals(this.state.activeIndex, index) && this.state.active
                      ? 'fadeInBigUp'
                      : ''
                  } `}
                >
                  <MenuItemContent {...item} />
                </div>
              </div>
            ))(data)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menu_items_count: state.menu_cat_items.total,
  menu_items: state.menu_cat_items.rows,
})

const mapDispatchToProps = dispatch => ({
  getItems: ({ keys }) => dispatch(MenuCatItems._fetchItems({ keys })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuCatPage)

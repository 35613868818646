import * as R from 'ramda'
import React from 'react'
import Moment from 'moment'
import { mapIndexed, notNilOrEmpty } from '../lib/helpers'
import Logo from '../assets/imgs/logo.png'

const Footer = props => (
  <footer className="footer row align-items-center">
    <div className="col col-md-3">
      <ul className="footer--nav-desktop__list">
        {notNilOrEmpty(props.menuLeft.items) &&
          mapIndexed((item, index) => (
            <li className="footer--nav-desktop__list__item" key={index}>
              <a href={item.url} target={item.target}>
                {item.title}
              </a>
            </li>
          ))(props.menuLeft.items)}
      </ul>
    </div>
    <div className="col d-none d-md-block text-center footer--copyright">
      <img src={Logo} alt="Kneaders Logo" className="footer--copyright__logo" />
      <p className="footer--copyright__text">
        {Moment().format('YYYY')} &copy; KNEADERS BAKERY &amp; CAFE
      </p>
    </div>
    <div className="col col-md-3">
      <ul className="footer--nav-desktop__list text-right">
        {notNilOrEmpty(props.menuRight.items) &&
          mapIndexed((item, index) => (
            <li className="footer--nav-desktop__list__item" key={index}>
              <a href={item.url} target={item.target}>
                {item.title}
              </a>
            </li>
          ))(props.menuRight.items)}
        {notNilOrEmpty(props.socialMenu.items) &&
          mapIndexed((item, index) => (
            <li
              className="d-none d-md-block footer--nav-desktop__list--social__item"
              key={index}
            >
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={`fa fa-${item.title} fa-2x`} />
              </a>
            </li>
          ))(R.reverse(props.socialMenu.items))}
      </ul>
    </div>
    <div className="col-12 d-md-none text-center footer--copyright">
      <img src={Logo} alt="Kneaders Logo" className="footer--copyright__logo" />
      <p className="footer--copyright__text">
        {Moment().format('YYYY')} &copy; KNEADERS BAKERY & CAFE
      </p>
      <ul className="d-md-up footer--nav-desktop__list d-flex justify-content-center">
        {notNilOrEmpty(props.socialMenu.items) &&
          mapIndexed((item, index) => (
            <li className="footer--nav-desktop__list--social__item" key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={`fa fa-${item.title} fa-2x`} />
              </a>
            </li>
          ))(props.socialMenu.items)}
      </ul>
    </div>
  </footer>
)

export default Footer

import React from 'react'
import SEO from '../components/seo'

export default () => {
  const container = React.useRef()

  React.useEffect(() => {
    let containerRefValue =  null;
    const script =
      typeof document !== 'undefined' && document.createElement('script')
    script.src =
      'https://api.chockstone.net:443/katana/v1/widgets/scripts/bootstrap_katana_account_pinless_dist.js?katanaDomain=hps&katanaChain=201031070428882&katanaApiKey=1vwvjn2vx1q46'


    if(container.current) {
      typeof document !== 'undefined' && container.current.appendChild(script)
      containerRefValue = container.current;
    }

    return () => {
      if (containerRefValue) typeof document !== 'undefined' && containerRefValue.removeChild(script)
    }
  }, [container])

  return (
    <section>
      <SEO title="Check your gift card Balance" />
      <div className="hero--emtpy"></div>
      <div className="content">
        <div
          className="gift-card-balance"
          ref={container}
          style={{
            minHeight: 'calc(100vh - 430px)',
            padding: '4rem 0',
            display: 'flex',
            placeContent: 'center'
          }}
        />
      </div>
    </section>
  )
}

import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleSlider from '../components/slider'
import { Page } from '../actions/'
import { notNilOrEmpty, mapIndexed, createMarkup } from '../lib/helpers'

class MenuPage extends React.Component {
  componentDidMount() {
    const keys = { slug: 'menu' }
    this.props.fetchPage({ keys })
  }
  componentDidUpdate(prevProps) {
    const keys = { slug: 'menu' }
    if (!R.equals(this.props.match.params.slug, prevProps.match.params.slug))
      this.props.fetchPage({ keys })
  }
  render() {
    const data = this.props.page
    return (
      <section>
        {data.full_slider && notNilOrEmpty(data.full_slider) ? (
          R.gte(data.full_slider.length, 2) ? (
            <SimpleSlider slides={data.full_slider} />
          ) : (
            R.equals(data.full_slider.length, 1) && (
              <a
                href="https://wordpress.kneadersdw.com/wp-content/uploads/2023/07/dining_printable_07-2023_Pricing.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="row justify-content-center align-items-end hero hero--single"
                  style={{
                    background: `url(${data.full_slider[0].image_url}) center no-repeat`,
                  }}
                >
                  <div className="slider--container__content text-center">
                    <h1 className="slider--container__content__title">
                      {data.full_slider[0].title}
                    </h1>
                    <h5 className="slider--container__content__subtitle">
                      {data.full_slider[0].subtitle}
                    </h5>
                  </div>
                </div>
              </a>
            )
          )
        ) : notNilOrEmpty(data.featured_img) ? (
          <div
            className="hero hero--single"
            style={{ background: `url(${data.featured_img}) center no-repeat` }}
          />
        ) : (
          <div className="hero--emtpy" />
        )}
        <div className="menu-content row">
          {notNilOrEmpty(data.select_menu_cat) &&
            mapIndexed((cat, index) => (
              <div
                className={`menu-content--inner-col col-md-${R.cond([
                  [R.gt(2), R.always('6')],
                  [R.T, R.always('4')],
                ])(index)} `}
                key={index}
              >
                <Link
                  className="link--red"
                  to={`/menu/${cat.menu_category.slug}`}
                >
                  <div
                    className="menu-content--inner-col__bg"
                    style={{
                      background: `url(${cat.image_url}) center no-repeat`,
                    }}
                  />
                  <h3
                    dangerouslySetInnerHTML={createMarkup(
                      cat.menu_category.name
                    )}
                  />
                </Link>
              </div>
            ))(data.select_menu_cat)}
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  page: state.page.page,
})

const mapDispatchToProps = dispatch => ({
  fetchPage: ({ keys }) => dispatch(Page._fetchPage({ keys })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuPage)

import * as R from 'ramda'
import Axios from 'axios'
import Bluebird from 'bluebird'
import React, { Component } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Headroom from 'react-headroom'
import { RouteFunctor, RouteActor } from './routes'
import { notNilOrEmpty, setKeys, getKeys } from './lib/helpers'
import { Menus } from './actions'
import Header from './components/header'
import Footer from './components/footer'

class App extends Component {
  state = {
    open: false,
    address: '',
    address_state: '',
    address_zip: '',
    location: {},
  }

  componentDidMount() {
    if (R.isEmpty(this.props.main_menu)) {
      Bluebird.all([
        this.props.fetchMenu('3', 'main'),
        this.props.fetchMenu('4', 'footer_left'),
        this.props.fetchMenu('6', 'footer_right'),
        this.props.fetchMenu('7', 'social'),
        this.props.fetchMenu('5', 'top'),
      ])
    }
  }

  _handleToggle = e => {
    e.preventDefault()
    this.setState({ open: !this.state.open })
  }

  _getLocalLocation = () =>
    getKeys().then(location => {
      this.setState({
        location,
      })
    })

  _getZip = () => {
    // get the IP of the user
    Axios.get(
      'https://api.ipdata.co/?api-key=4368bd3dec26b49a6c9aab6340ef4b1493582b2d6e955e0edf24c160'
    )
      .then(res => {
        return res.data
      })
      // pass the result of the ip to the ipdata and get the info of that ip
      .then(data => {
        Axios.get('https://api.ipdata.co/' + data.ip).then(res =>
          // save info to local storage
          setKeys({
            address:
              res.data.city +
              ', ' +
              res.data.region_code +
              ', ' +
              res.data.country_code,
            address_state: res.data.region_code,
            address_zip: res.data.postal,
          }).then(() => this._getLocalLocation())
        )
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Headroom>
            <Header
              toggle={this._handleToggle}
              open={this.state.open}
              mainMenu={this.props.main_menu}
              topMenu={this.props.top_menu}
              socialMenu={this.props.social_menu}
              geolocationZip={
                notNilOrEmpty(this.state.location)
                  ? this.state.location.address_zip
                  : ''
              }
              location={
                notNilOrEmpty(this.state.location)
                  ? this.state.location.address
                  : ''
              }
              geolocationState={
                notNilOrEmpty(this.state.location)
                  ? this.state.location.address_state
                  : ''
              }
            />
          </Headroom>
          <Switch>
            {RouteFunctor.map((route, key) => (
              <RouteActor key={key} {...route} />
            ))}
          </Switch>
          <Footer
            menuLeft={this.props.footer_menu_left}
            menuRight={this.props.footer_menu_right}
            socialMenu={this.props.social_menu}
          />
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({
  main_menu: state.menu.main,
  footer_menu_left: state.menu.footer_left,
  footer_menu_right: state.menu.footer_right,
  social_menu: state.menu.social,
  top_menu: state.menu.top,
})

const mapDispatchToProps = dispatch => ({
  fetchMenu: (id, menu_type) => dispatch(Menus.fetchMenu(id, menu_type)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

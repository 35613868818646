import {Api} from '../lib'

const TYPE = {
  main: 'GET_MAIN_MENU',
  social: 'GET_SOCIAL_MENU',
  top: 'GET_TOP_MENU',
  footer_left: 'GET_FOOTER_MENU_LEFT',
  footer_right: 'GET_FOOTER_MENU_RIGHT'
}

const fetchMenu = (menu_id, menu_type) => (dispatch, getState) =>
  Api.get('menus', {
    keys: {menu_id: menu_id}
  })
    .then(menu => dispatch({type: TYPE[menu_type], payload: menu.data}))
    .catch(err => console.log(err))

export default {TYPE, fetchMenu}

import * as R from 'ramda'
import React from 'react'
import Slider from 'react-slick'
import { mapIndexed, notNilOrEmpty } from '../lib/helpers'

const carouselSettings = {
  autoplay: true,
  autoplaySpeed: 8000,
  adaptiveHeight: false,
  className: 'slider-component',
  centerMode: false,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
}

const SimpleSlider = props => (
  <section className="slider hero">
    {notNilOrEmpty(props) && (
      <Slider {...carouselSettings}>
        {mapIndexed((item, index) => (
          <a
            href={item.link}
            target={R.equals(item.link_target, 'yes') ? '_blank' : ''}
            key={index}
            className="slider--link"
          >
            <div
              className="slider--container row align-items-end justify-content-center"
              key={index}
              style={{
                backgroundImage: `url(${
                  props.mobile ? item.image_url_mobile : item.image_url
                })`,
              }}
            >
              <div className="slider--container__content text-center">
                <h1 className="slider--container__content__title">
                  {item.title}
                </h1>
                <h5 className="slider--container__content__subtitle">
                  {item.subtitle}
                </h5>
              </div>
            </div>
          </a>
        ))(props.slides)}
      </Slider>
    )}
  </section>
)

export default SimpleSlider

import * as R from 'ramda'
import { Blog } from '../actions'

const reducer = (state = {}, action) =>
  R.cond([
    [R.equals(Blog.TYPE.get_posts), R.always(action.payload)],
    [R.T, R.always(state)],
  ])(action.type)

export default reducer

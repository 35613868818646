import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import { Blog } from '../actions/'
import { createMarkup, mapIndexed, notNilOrEmpty } from '../lib/helpers'

const BlogPage = props => {
  React.useEffect(() => {
    props.fetchBlogPosts()
  }, [props])

  const data = props.blog
  return (
    <section className="page--blog">
      <div className="hero--emtpy" />
      <div className="content">
        <div className="row align-items-center justify-content-center flex-column page--blog__title">
          <h1>Slice of Life</h1>
          <h2>Kneaders Blog</h2>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            {notNilOrEmpty(data) &&
              mapIndexed((item, index) => (
                <div className="row page--blog__list__entry" key={index}>
                  <div className="col-lg-3">
                    <img src={item.featured_img} alt={item.title} />
                  </div>
                  <div className="col-lg-9">
                    <Link className="title_link" to={`/blog/${item.slug}`}>
                      <h3 className="title">{item.title}</h3>
                    </Link>
                    <h4 className="date">
                      {Moment(item.date).format('MMMM DD, YYYY')}
                    </h4>
                    <div
                      className="excerpt"
                      dangerouslySetInnerHTML={createMarkup(item.excerpt)}
                    />
                    <Link
                      className="btn btn-sm btn-block btn--red-outline"
                      to={`/blog/${item.slug}`}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))(data)}
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  blog: state.blog,
})

const mapDispatchToProps = dispatch => ({
  fetchBlogPosts: () => dispatch(Blog._fetchPosts()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogPage)

import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../actions/'
import { createMarkup } from '../lib/helpers'

class NotFound extends React.Component {
  componentDidMount() {
    const keys = { slug: '404-2' }
    this.props.fetchPage({ keys })
  }
  render() {
    const data = this.props.page
    return (
      <React.Fragment>
        <section>
          <div className="hero--emtpy" />
          <div className="content">
            <div dangerouslySetInnerHTML={createMarkup(data.content)} />
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  page: state.page.page,
})

const mapDispatchToProps = dispatch => ({
  fetchPage: ({ keys }) => dispatch(Page._fetchPage({ keys })),
})
export default connect(mapStateToProps, mapDispatchToProps)(NotFound)

import { combineReducers } from 'redux'
import menu from './menu'
import menu_cat_items from './menu_cat_items'
import page from './page'
import locations from './locations'
import blog from './blog'
import post from './post'

export default combineReducers({
  blog,
  locations,
  menu,
  menu_cat_items,
  page,
  post,
})

import { Api } from '../lib'

const TYPE = {
  get_home: 'GET_HOME_PAGE',
  get_page: 'GET_PAGE',
  get_cats: 'GET_MENU_CATEGORIES',
  page_not_found: 'PAGE_NOT_FOUND',
}

const _fetchPage = ({ keys }, type) => dispatch =>
  Api.get('page', { keys })
    .then(page => {
      if (type === 'home_page')
        dispatch({ type: TYPE.get_home, payload: page.data })
      else dispatch({ type: TYPE.get_page, payload: page.data })
    })
    .catch(err => {
      if (err.response.status === 404) dispatch({ type: TYPE.page_not_found })
    })

const _fetchMenuCats = () => dispatch =>
  Api.get('menu_cats').then(cat => {
    dispatch({ type: TYPE.get_cats, payload: cat.data })
  })

export default {
  TYPE,
  _fetchPage,
  _fetchMenuCats,
}

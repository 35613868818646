import * as R from 'ramda'
import { Menus } from '../actions'

const DEFAULT_STATE = {
  main: [],
  footer_left: [],
  footer_right: [],
  social: [],
  top: [],
}

const reducer = (state = DEFAULT_STATE, action) =>
  R.cond([
    [R.equals(Menus.TYPE.main), () => R.merge(state, { main: action.payload })],
    [
      R.equals(Menus.TYPE.social),
      () => R.merge(state, { social: action.payload }),
    ],
    [
      R.equals(Menus.TYPE.footer_left),
      () => R.merge(state, { footer_left: action.payload }),
    ],
    [
      R.equals(Menus.TYPE.footer_right),
      () => R.merge(state, { footer_right: action.payload }),
    ],
    [R.equals(Menus.TYPE.top), () => R.merge(state, { top: action.payload })],
    [R.T, R.always(state)],
  ])(action.type)

export default reducer

import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import SimpleSlider from '../components/slider'
import { Page } from '../actions'
import { notNilOrEmpty, createMarkup } from '../lib/helpers'

class Home extends React.Component {
  state = {
    mobile: false,
  }
  componentDidMount() {
    const keys = { slug: 'home-page' }
    this.props.getPage({ keys }, 'home_page')
    R.lte(window.innerWidth, 500) && this.setState({ mobile: true })
  }
  componendDidUpdate(prevProps) {
    const keys = { slug: 'home-page' }
    if (!R.equals(prevProps.match.params.slug, this.props.match.params.slug))
      this.props.getPage({ keys })
  }
  render() {
    const data = this.props.home
    return (
      <React.Fragment>
        {notNilOrEmpty(data) && (
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={data.meta.yoast_wpseo_metadesc} />
            <meta
              property="og:description"
              content={data.meta.yoast_wpseo_metadesc}
            />
            <meta property="og:title" content={data.meta.yoast_wpseo_title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://kneaders.com/" />
            <meta
              property="og:image"
              content={data.meta.yoast_wpseo_opengraph_image}
            />
            <title>{data.meta.yoast_wpseo_title}</title>
          </Helmet>
        )}
        <main>
          {notNilOrEmpty(data) && (
            <div>
              {notNilOrEmpty(data.full_slider) && (
                <SimpleSlider
                  mobile={this.state.mobile}
                  slides={data.full_slider}
                />
              )}
              <div dangerouslySetInnerHTML={createMarkup(data.content)} />
              {notNilOrEmpty(data.bottom_slider[0].image_url) ||
                (!data.bottom_slider[0].image_url && (
                  <div className="hero--video">
                    <div className="row align-items-center justify-content-center">
                      <video
                        id="bgvid"
                        playsInline
                        autoPlay
                        muted
                        loop
                        src={data.bottom_slider[0].image_url.url}
                      />
                      <div className="slider--container__content text-center">
                        <h1 className="slider--container__content__title">
                          {data.bottom_slider[0].title}
                        </h1>
                        <h5 className="slider--container__content__subtitle">
                          {data.bottom_slider[0].subtitle}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </main>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  home: state.page.home,
})

const mapDispatchToProps = dispatch => ({
  getPage: ({ keys }, type) => dispatch(Page._fetchPage({ keys }, type)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)

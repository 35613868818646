import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Page, Locations } from '../actions/'
import {
  createMarkup,
  getKeys,
  notNilOrEmpty,
  mapIndexed,
} from '../lib/helpers'
import LocationsList from '../components/locationsList'
import USAmap from '../components/usa_map'

const SELECTED_MAP_ZOOM_LEVEL = 18

const STATES_COORD = {
  AZ: {
    lat: 32.97722,
    lng: -112.126804,
    zoom: 8,
  },
  CO: {
    lat: 39.497601,
    lng: -104.736529,
    zoom: 8,
  },
  ID: {
    lat: 43.704855,
    lng: -114.228418,
    zoom: 8,
  },
  NV: {
    lat: 36.128615,
    lng: -115.116331,
    zoom: 11,
  },
  TX: {
    lat: 29.521877,
    lng: -98.387708,
    zoom: 10,
  },
  UT: {
    lat: 39.662509,
    lng: -111.479921,
    zoom: 7,
  },
}

class LocationsPage extends React.Component {
  state = {
    location: {},
    searchingZipcode: null,
    selectedState: this.props.location.state
      ? this.props.location.state.geolocationState
      : '',
    searchZip: '',
    openInfoWindow: false,
    geolocationZip: this.props.location.state
      ? this.props.location.state.geolocationZip
      : '',
    restaurantInfo: {
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      order_url: '',
      hours: '',
    },
    position: {
      latitude: null,
      longitude: null,
    },
  }
  componentWillMount() {
    // if (R.isEmpty(this.state.location)) {
    //   if (window.navigator.geolocation) {
    //     window.navigator.geolocation.getCurrentPosition(
    //       this._successFn,
    //       this._errorFn
    //     )
    //   }
    // }

    if (notNilOrEmpty(this.props.location.state))
      this.props.fetchLocations({ keys: { state: this.state.geolocationZip } })
  }

  componentDidMount() {
    const keys = { slug: 'locations' }
    this.props.fetchPage({ keys })
    this.geocoder = new window.google.maps.Geocoder()
    this._infoWindow = new window.google.maps.InfoWindow()

    // this._getLocalLocation()
    // this.latlng = (lat, lng) => new window.google.maps.LatLng(lat, lng)
  }

  componentDidUpdate(prevProps, prevState) {
    const keys = { slug: 'locations' }

    if (
      notNilOrEmpty(this.props.location.state) &&
      notNilOrEmpty(this.state.selectedState)
    )
      this._scrollTo('.page--locations__maps')

    if (
      notNilOrEmpty(this.props.location.state) &&
      !R.equals(prevProps.location.state, this.props.location.state)
    ) {
      this.props.fetchLocations({ keys: { state: this.state.selectedState } })
    }

    // if the slug is different then request the new page
    if (!R.equals(this.props.match.params.slug, prevProps.match.params.slug))
      this.props.fetchPage({ keys })

    if (notNilOrEmpty(this.props.error)) this._scrollTo('.no-results')

    // Draw Map when clicking a State
    if (
      !R.equals(this.props.restaurants.rows, prevProps.restaurants.rows) ||
      !R.equals(this.state.position, prevState.position)
    ) {
      notNilOrEmpty(this.props.restaurants) &&
        this.state.searchingZipcode &&
        this.setState(
          {
            selectedState: this.props.restaurants.rows[0].state,
            searchZip: '',
            position: {
              latitude: STATES_COORD[this.props.restaurants.rows[0].state].lat,
              longitude: STATES_COORD[this.props.restaurants.rows[0].state].lng,
            },
          },
          () => {
            this._scrollTo('.page--locations__maps')
            this._renderMap(this.state)
            // Drop all the markers in the selected state
            this._drawMarkers(this.props.restaurants.rows)
            this.setState({
              searchingZipcode: false,
            })
          }
        )
      if (notNilOrEmpty(this.state.selectedState)) {
        this._renderMap(prevState)
        // Drop all the markers in the selected state
        this._drawMarkers(this.props.restaurants.rows)
      }
    }
  }

  // setting the content based on the state.restaurantInfo
  // and then opening it
  _setContentAndOpenInfoWindow = (marker, item) => {
    this._infoWindow.setContent(`
        <h3>${this.state.restaurantInfo.name}</h3>
        <a href="https://www.google.com/maps/dir/?api=1&destination=${
          item.geocode[0].results[0].geometry.location.lat
        },${item.geocode[0].results[0].geometry.location.lng}" target="_blank"">
        <p>${this.state.restaurantInfo.address}<br />
        ${this.state.restaurantInfo.city},
        ${this.state.restaurantInfo.state}
        ${this.state.restaurantInfo.zip}<br />
        </a>
        ${this.state.restaurantInfo.phone}</p>
        <p>${this.state.restaurantInfo.hours}</p>
        <div class="row">
        <div class="col">
          <a class="btn btn--red-outline btn-lg ${!this.state.restaurantInfo
            .order_url && 'd-none'}" href="${
      this.state.restaurantInfo.order_url
        ? this.state.restaurantInfo.order_url
        : 'https://order.kneaders.com'
    }" target="_blank">
            Order Now
            </a>
        </div>
        <div class="col">
          <a class="btn btn--red-outline btn-lg" href="tel:${
            this.state.restaurantInfo.phone
          }">
            Call In
          </a>
        </div>
      `)
    this._infoWindow.open(this.map, marker)
  }

  // fn to render the map
  _renderMap = element => {
    this.map = new window.google.maps.Map(
      document.querySelector('.map-container'),
      !R.equals(element, this.state)
        ? {
            zoom: SELECTED_MAP_ZOOM_LEVEL,
            center: {
              lat: this.state.position.latitude,
              lng: this.state.position.longitude,
            },
          }
        : {
            zoom: STATES_COORD[this.state.selectedState].zoom,
            center: {
              lat: STATES_COORD[this.state.selectedState].lat,
              lng: STATES_COORD[this.state.selectedState].lng,
            },
          }
    )
  }

  // mapping all the results in the props.restaurants.rows
  // then adding a click event to each marker and passing the state to display info
  _drawMarkers = items => {
    const arr = []
    mapIndexed((item, index) => {
      this.markers = new window.google.maps.Marker({
        map: this.map,
        animation: window.google.maps.Animation.DROP,
        title: item.title,
        position: {
          lat: item.geocode[0].results[0].geometry.location.lat,
          lng: item.geocode[0].results[0].geometry.location.lng,
        },
      })
      arr.push(this.markers)
      this.markers.addListener('click', () => {
        this.setRestaurantInfo(item)
        this._setContentAndOpenInfoWindow(arr[index], item)
      })
    })(items)
  }

  // success fn if we can get the coords from user
  _successFn = position => {
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    this._codeLatLng(lat, lng)
  }

  _errorFn = () => console.log('Geocoder Failed')

  _codeLatLng = (lat, lng) => {
    this.geocoder.geocode(
      { latLng: this.latlng(lat, lng) },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this._getZip()
        } else {
          console.log('Geocoder failed due to: ' + status)
        }
      }
    )
  }

  _getLocalLocation = () =>
    getKeys().then(location => {
      this.setState({
        location,
      })
    })

  onSTchange = e => {
    e.preventDefault()
    this.setState(
      {
        searchZip: '',
        selectedState: e.target.id,
        searchingZipcode: false,
      },
      () => {
        this.props.fetchLocations({ keys: { state: this.state.selectedState } })
        this._scrollTo('.page--locations__maps')
      }
    )
  }

  _scrollTo = identifier => {
    window.scrollTo({
      top: document.querySelector(identifier).offsetTop - 60,
      behavior: 'smooth',
    })
  }

  setRestaurantInfo = i =>
    this.setState({
      restaurantInfo: {
        name: i.title,
        address: i.address,
        city: i.city,
        state: i.state,
        zip: i.zip,
        phone: i.phone,
        hours: i.hours,
        order_url: i.menu_url,
      },
    })

  onSelectStore = i => {
    this.setRestaurantInfo(i)
    this.setState(
      {
        openInfoWindow: true,
        position: {
          latitude: i.geocode[0].results[0].geometry.location.lat,
          longitude: i.geocode[0].results[0].geometry.location.lng,
        },
      },
      () => this._scrollTo('.map-container')
    )
  }

  _onZipValue = e => {
    e.preventDefault()
    this.setState({
      searchZip: e.target.value,
    })
  }

  _onSubmit = e => {
    e.preventDefault()
    this.setState(
      {
        searchingZipcode: true,
      },
      () => {
        this.props.fetchLocations({ keys: { state: this.state.searchZip } })
      }
    )
  }

  render() {
    const page = this.props.page
    const restaurants = this.props.restaurants
    const selectedState = this.state.selectedState

    return (
      <div className="row">
        <Helmet />
        <section className="page--locations">
          {notNilOrEmpty(page) ? (
            <div className="row hero">
              <div className="col-md-4 content-left equalH align--center--v">
                <div dangerouslySetInnerHTML={createMarkup(page.content)} />
                <div className="row justify-content-center">
                  <div className="col-xs-8 col-sm-10">
                    <form onSubmit={this._onSubmit}>
                      <div className="form-group find-fields row justify-content-center">
                        <input
                          type="text"
                          name="zip"
                          className="form-control input-lg input-find"
                          id="zip"
                          placeholder="Zip Code"
                          required
                          onChange={this._onZipValue}
                          value={this.state.searchZip}
                        />
                        <button
                          className="btn btn-sm btn--red-outline btn--find"
                          type="submit"
                        >
                          Find
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-8 equalH align--center--v">
                <USAmap stateChange={this.onSTchange} />
                <div className="row align-items-center justify-content-center">
                  <ul className="align-items-center states-list text-center">
                    <li className="states-list__state">
                      <h6 id="AZ" onClick={this.onSTchange}>
                        Arizona
                      </h6>
                    </li>
                    <li className="states-list__state">
                      <h6 id="CO" onClick={this.onSTchange}>
                        Colorado
                      </h6>
                    </li>
                    <li className="states-list__state">
                      <h6 id="ID" onClick={this.onSTchange}>
                        Idaho
                      </h6>
                    </li>
                    <li className="states-list__state">
                      <h6 id="NV" onClick={this.onSTchange}>
                        Nevada
                      </h6>
                    </li>
                    <li className="states-list__state">
                      <h6 id="TX" onClick={this.onSTchange}>
                        Texas
                      </h6>
                    </li>
                    <li className="states-list__state">
                      <h6 id="UT" onClick={this.onSTchange}>
                        Utah
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          <div className="content">
            <div
              className={`page--locations__maps row ${
                notNilOrEmpty(selectedState) || notNilOrEmpty(this.props.error)
                  ? 'animate fadeIn show'
                  : 'd-none'
              }`}
            >
              {this.props.error && notNilOrEmpty(this.props.error) ? (
                <div
                  className="col d-flex align-items-center justify-content-center no-results"
                  style={{ marginTop: '20px' }}
                >
                  <h3>
                    No results found near this zip code. Please click a state
                    above to find other locations.
                  </h3>
                </div>
              ) : (
                <React.Fragment>
                  <p
                    className="col-12 text-center d-none d-lg-block text-uppercase"
                    style={{ marginTop: '1rem', color: '#d24b27' }}
                  >
                    <strong>Click the pin for location details</strong>
                  </p>
                  <div className="col-lg-3 col-xs-12">
                    <LocationsList
                      onSelectStore={this.onSelectStore}
                      locations={restaurants.rows}
                    />
                  </div>
                  <div className="col map-container" />
                </React.Fragment>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  page: state.page.page,
  restaurants: state.locations.locations,
  error: state.locations.error,
})

const mapDispatchToProps = dispatch => ({
  fetchPage: ({ keys }) => dispatch(Page._fetchPage({ keys })),
  fetchLocations: ({ keys }) => dispatch(Locations._fetchState({ keys })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsPage)

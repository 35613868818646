import { Api } from '../lib'

const TYPE = {
  get_posts: 'GET_BLOG_POSTS',
  get_post: 'GET_POST',
}

const _fetchPosts = () => dispatch =>
  Api.get('blog')
    .then(blog => dispatch({ type: TYPE.get_posts, payload: blog.data }))
    .catch(err => console.log(err))

const _fetchPost = ({ keys }) => dispatch =>
  Api.get('blog_post', { keys })
    .then(post => dispatch({ type: TYPE.get_post, payload: post.data }))
    .catch(err => console.log(err))

export default {
  _fetchPosts,
  _fetchPost,
  TYPE,
}

import * as R from 'ramda'
import { Page } from '../actions'

const DEFAULT_STATE = {
  home: [],
  page: [],
  menu_page: [],
}

const reducer = (state = DEFAULT_STATE, action) =>
  R.cond([
    [
      R.equals(Page.TYPE.get_home),
      () => R.merge(state, { home: action.payload }),
    ],
    [
      R.equals(Page.TYPE.get_cats),
      () => R.merge(state, { menu_cats: action.payload }),
    ],
    [
      R.equals(Page.TYPE.get_page),
      () => R.merge(state, { page: action.payload }),
    ],
    [
      R.equals(Page.TYPE.page_not_found),
      () => R.merge(state, { page: { error: { status: 404 } } }),
    ],
    [R.T, R.always(state)],
  ])(action.type)

export default reducer

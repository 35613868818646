import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import SimpleSlider from '../components/slider'
import NotFound from './not_found'
import { Page } from '../actions/'
import { createMarkup, mapIndexed, notNilOrEmpty } from '../lib/helpers'
import SEO from '../components/seo'

class PageContainer extends React.Component {
  state = {
    readMore: false,
    readMoreIdex: null,
    fade: 'fadeIn'
  }
  componentDidMount() {
    if (this.props.match.params.sub_page_slug) {
      const keys = { slug: this.props.match.params.sub_page_slug }
      this.props.fetchPage({ keys })
    } else {
      const keys = { slug: this.props.match.params.slug }
      this.props.fetchPage({ keys })
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.sub_page_slug &&
      !R.equals(
        this.props.match.params.sub_page_slug,
        prevProps.match.params.sub_page_slug
      )
    ) {
      const keys = { slug: this.props.match.params.sub_page_slug }
      this.props.fetchPage({ keys })
    } else {
      const keys = { slug: this.props.match.params.slug }
      if (
        !R.equals(this.props.match.params.slug, prevProps.match.params.slug)
      ) {
        this.props.fetchPage({ keys })
      }
    }
  }
  _onReadMore = index =>
    this.setState({ readMore: !this.state.readMore, readMoreIndex: index })

  render() {
    const data = this.props.page
    const err_status = R.path(['error', 'status'])(data)

    if (err_status === 404) return <NotFound />
    else
      return (
        <>
          {notNilOrEmpty(data) && (
            <SEO
              description={data.meta.yoast_wpseo_metadesc}
              title={data.title}
              image={data.featured_img}
            />
          )}
          <section>
            {data.full_slider && notNilOrEmpty(data.full_slider) ? (
              R.gte(data.full_slider.length, 2) ? (
                <SimpleSlider slides={data.full_slider} />
              ) : (
                R.equals(data.full_slider.length, 1) && (
                  <div
                    className="hero hero--single"
                    style={{
                      background: `url(${data.full_slider[0].image_url})`
                    }}
                  />
                )
              )
            ) : (
              <div className="hero--emtpy" />
            )}
            <div className="content">
              {R.equals(data.slug, 'executive-bios') ? (
                <div className="content--executive-bios">
                  <h1 className="d-flex justify-content-center">
                    {data.title}
                  </h1>
                  {mapIndexed((item, index) => (
                    <div
                      className="row content--executive-bios__person"
                      key={index}
                    >
                      <div className="col-md-4">
                        <img
                          src={item.founders_image}
                          alt={item.founders_name}
                        />
                      </div>
                      <div className="col-md-8">
                        <h3>{item.founders_name}</h3>
                        <h4>{item.founders_title}</h4>
                        <div className="founders-copy">
                          <div
                            className="founders-copy--intro"
                            dangerouslySetInnerHTML={createMarkup(
                              item.founders_intro
                            )}
                          />
                          <div
                            className={`founders-copy--content animated
                          ${
                            this.state.readMore &&
                            this.state.readMoreIndex === index
                              ? 'fadeIn expand'
                              : ''
                          }
                        `}
                            dangerouslySetInnerHTML={createMarkup(
                              item.founders_content
                            )}
                          />
                          <button
                            className="btn btn-sm btn-block btn--red-outline align--right"
                            onClick={() => this._onReadMore(index)}
                          >
                            {this.state.readMore &&
                            this.state.readMoreIndex === index
                              ? 'Less...'
                              : 'Read More...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))(data.founders_info.founders_element)}
                </div>
              ) : (
                <div dangerouslySetInnerHTML={createMarkup(data.content)} />
              )}
            </div>
          </section>
        </>
      )
  }
}

const mapStateToProps = state => ({
  page: state.page.page
})

const mapDispatchToProps = dispatch => ({
  fetchPage: ({ keys }) => dispatch(Page._fetchPage({ keys }))
})

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer)

import * as R from 'ramda'
import { Locations } from '../actions'

const DEFAULT_STATE = {
  error: [],
  locations: [],
}

const reducer = (state = DEFAULT_STATE, action) =>
  R.cond([
    [
      R.equals(Locations.TYPE.receive_location),
      () =>
        R.merge(state, {
          locations: action.payload,
          error: DEFAULT_STATE.error,
        }),
    ],
    [
      R.equals(Locations.TYPE.not_found),
      () =>
        R.merge(state, {
          error: { status: 404 },
        }),
    ],
    [R.T, R.always(state)],
  ])(action.type)

export default reducer

const prefixURL = '/wp-json/kneaders/v1'

export default {
  // WP API MAPPER
  menus: '/wp-json/wp-api-menus/v2/menus/:menu_id',
  blog_post: prefixURL + '/post/:slug',
  blog: prefixURL + '/posts',
  page: prefixURL + '/page/:slug',
  menu_cats: prefixURL + '/menu_cat',
  menu_items: prefixURL + '/menu_items/:slug',
  locations: prefixURL + '/locations/:state',
}

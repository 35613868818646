import * as R from 'ramda'
import Localforage from 'localforage'

const notEmpty = R.complement(R.isEmpty)

const notNil = R.complement(R.isNil)

const notNilOrEmpty = R.complement(R.either(R.isNil, R.isEmpty))

const notEquals = R.curry((a, b) => R.complement(R.equals(a))(b))

const nilOrEmpty = R.either(R.isNil, R.isEmpty)

const mapIndexed = R.addIndex(R.map)

const createMarkup = content => {
  return { __html: content }
}

// Create DB Instance
const KeyStore = Localforage.createInstance({ name: 'Geolocation' })

// Get keys from DB
const getKeys = () => KeyStore.getItem('location').then(keys => keys || {})

// Set keys to DB
const setKeys = keys => KeyStore.setItem('location', keys)

export {
  createMarkup,
  notEmpty,
  notNil,
  notEquals,
  nilOrEmpty,
  notNilOrEmpty,
  mapIndexed,
  KeyStore,
  getKeys,
  setKeys,
}

import React from 'react'
import { Link } from 'react-router-dom'
import { mapIndexed, notNilOrEmpty } from '../lib/helpers'
import OffCanvasMenu from './off_canvas_menu'
import Logo from '../assets/imgs/logo.png'

export default props => (
  <header className="header">
    <OffCanvasMenu
      menuItems={props.mainMenu.items}
      topMenuItems={props.topMenu.items}
      toggle={props.toggle}
      open={props.open}
      geolocationZip={props.geolocationZip ? props.geolocationZip : ''}
      geolocationState={props.geolocationState ? props.geolocationState : ''}
      geolocation_address={props.location ? props.location : ''}
    />
    <nav className="d-none d-lg-block header--nav-desktop row align-items-center">
      <div className="col">
        <Link to="/">
          <img
            src={Logo}
            alt="Kneaders Logo"
            className="header--nav-desktop__logo"
          />
        </Link>
      </div>
      <div className="col-md-8">
        <ul className="header--nav-desktop__list--top row justify-content-end align-items-center">
          {notNilOrEmpty(props.topMenu.items) &&
            mapIndexed((item, index) => (
              <li className="header--nav-desktop__list--top__item" key={index}>
                <a href={item.url} target={item.target}>
                  {item.title}
                </a>
              </li>
            ))(props.topMenu.items)}
          {notNilOrEmpty(props.socialMenu.items) &&
            mapIndexed((item, index) => (
              <li
                className="header--nav-desktop__list--top--social__item"
                key={index}
              >
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <i className={`fa fa-${item.title} fa-2x`} />
                </a>
              </li>
            ))(props.socialMenu.items)}
        </ul>
        <div className="row align-items-center justify-content-end">
          {notNilOrEmpty(props.geolocationZip) && (
            <div className="header--nav-desktop__location ">
              <Link
                className="d-flex flex-column text-center"
                to={{
                  pathname: '/locations',
                  state: {
                    geolocationZip: props.geolocationZip,
                    geolocationState: props.geolocationState,
                  },
                }}
              >
                <i className="fa fa-map-marker" />
                <span className="header--nav-desktop__location__address">
                  {props.location}
                </span>
              </Link>
            </div>
          )}
          <a
            href="https://order.kneaders.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm btn-block btn--red-outline"
          >
            ORDER NOW
          </a>
          <ul className="header--nav-desktop__list row">
            {notNilOrEmpty(props.mainMenu.items) &&
              mapIndexed((item, index) => (
                <li className="header--nav-desktop__list__item" key={index}>
                  <Link to={item.url.replace(/^.*\/\/[^/]+/, '')}>
                    {item.title}
                  </Link>
                </li>
              ))(props.mainMenu.items)}
          </ul>
        </div>
      </div>
    </nav>
  </header>
)

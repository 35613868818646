import { Api } from '../lib'

const TYPE = {
  receive_location: 'GET_LOCATION',
  not_found: 'NOT_FOUND',
}

const _fetchState = ({ keys }) => dispatch =>
  Api.get('locations', { keys })
    .then(location =>
      dispatch({ type: TYPE.receive_location, payload: location.data })
    )
    .catch(err => {
      if (err.response.status === 404) dispatch({ type: TYPE.not_found })
    })

export default {
  TYPE,
  _fetchState,
}
